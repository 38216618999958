import './qr-success.scss'
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import qrImage from "../../../assets/images/qrcode.png"
import { useLocation, useNavigate } from 'react-router-dom';
import CommonHeader from '../../components/common/common-header/common-header';
import { routerPath } from '../../navigation/path';
import { useEffect, useRef, useState } from 'react';
import ApiService from '../../services/http/api-service';
import { ApiConfiguration } from '../../services/http/api-configuration';
import { FormConfig } from '../../services/http/api-constant';
const QrSuccess = () => {
    const location = useLocation();
    const navigate = useNavigate()

    const queryParams = new URLSearchParams(location.search);
    const dataString = queryParams.get('offer_detail');
    const offerDetail = JSON.parse(decodeURIComponent(dataString));
    console.log(offerDetail)
    const [qrDetail, setQrDetail] = useState({})

    const restorantCode = () => {
        navigate(routerPath.restaurantCode)
    }

    const isApiLoadOnes = useRef(false)


    useEffect(() => {
        if (!isApiLoadOnes.current) {
            getQrCode()
            isApiLoadOnes.current = true
        }
    }, [])

    const getQrCode = () => {
        const formData = new FormData()
        formData.append('restaurant_offer_id', offerDetail.restaurant_offer_id)
        ApiService.post(ApiConfiguration.qrSuccess, formData, FormConfig).then(res => {
            console.log(res)
            setQrDetail(res.data.data)
        }).catch(err => {
            console.log(err)
        })
    }


    return (
        <>
            <CommonHeader header={'Offer Details'}></CommonHeader>
            <Container className='qr-success-page'>
                <Row>
                    <Col md={12}>

                        <div className="mt-5 text-center">
                            <h4>
                                Congratulations!
                            </h4>
                            <p className='activated-offer'>
                                You have activated the offer
                            </p>
                        </div>
                    </Col>

                    <Col md={12}>

                        <div className="mt-2 text-center">
                            <img src={qrDetail.link} alt="" />
                        </div>
                    </Col>

                    <Col md={12}>

                        <div className="mt-5 text-center">
                            <p className='show-qr-info'>
                                Show the above QR code to the
                                server to avail the offer
                            </p>
                        </div>

                        <div className="form-group mt-4 text-center">
                            Unable to avail the offer ?
                            <button type="button" onClick={restorantCode} className="resend-btn">
                                click here
                            </button>
                        </div>
                    </Col>
                </Row>
            </Container>
        </>
    )
}

export default QrSuccess;