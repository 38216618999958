import React from 'react';
import { Route, Navigate, Routes } from 'react-router-dom';
import { routerPath } from '../../navigation/path';
import { AuthorizationContext } from './AuthorizationContext';
import { useContext } from 'react';
import { useNavigate, } from 'react-router-dom';
import { useEffect } from 'react';


const ProtectedRoute = ({ element }) => {
    const { isAuthorized } = useContext(AuthorizationContext);

    const navigate = useNavigate();



    useEffect(() => {
        const handleBackButton = (e) => {
            console.log(e)
            e.preventDefault();
            console.log('handleBackButton')
            navigate('/restaurant-code', { replace: true })
            // if (!isAuthorized) {
            //     localStorage.clear()
            // }


        };

        // Add event listeners
        window.addEventListener('popstate', handleBackButton);


        // Clean up the event listeners on component unmount
        return () => {
            window.removeEventListener('popstate', handleBackButton);


        };
    }, [navigate]);


    return isAuthorized ? (
        element
    ) : (
        <Navigate to={routerPath.splash} replace state={{ from: window.location.pathname }} />
    );
};


export default ProtectedRoute;



