import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import './user-profile.scss'
import { useLocation, useNavigate } from "react-router-dom";
import backBtn from "../../../assets/images/Left.png"
import user from "../../../assets/images/user.png"
import pencial from "../../../assets/images/pencial.png"
import rightArrow from "../../../assets/images/Caret right.svg"
import { AuthorizationContext } from "../../services/auth/AuthorizationContext";
import { useContext, useEffect, useState } from "react";
import CommonAlert from "../../components/common/common-alert/common-alert";
import { routerPath } from "../../navigation/path";
import { ApiConfiguration } from "../../services/http/api-configuration";
import ApiService from "../../services/http/api-service";
import { FormConfig } from "../../services/http/api-constant";


const UserProfile = () => {
    const { isAuthorized, login, logout } = useContext(AuthorizationContext);
    const userDetail = JSON.parse(localStorage.getItem('user_detail'));
    const navigate = useNavigate()
    const location = useLocation()
    const [profileImg, setProfileImg] = useState('')
    const [userData, setUserData] = useState({})


    const goBack = () => {
        navigate(routerPath.offers)
    }

    useEffect(() => {
        getProfileData()
    }, [])
    const [isLogout, setIsLogout] = useState(false)

    const profileTab = (selected) => {
        console.log(selected)
        selected == 'home' && navigate(routerPath.offers)
        selected == 'profile' && navigate(routerPath.myProfile)
        selected == 'offer' && navigate(routerPath.redeemOffer)
        selected == 'about-us' && navigate(routerPath.aboutUs)
        selected == 'contact-us' && navigate(routerPath.contectUs)
    }

    const cancelLogout = () => {
        setIsLogout(false)

    }

    const getProfileData = () => {
        ApiService.get(ApiConfiguration.myprofile).then(response => {
            const data = response.data.data[0];
            setUserData(response.data.data[0])
            setProfileImg(data.profile_image)
        }).catch(error => {
            console.error('Error fetching personal details:', error);
        });
    }
    const sendToMobilePage = () => {
        logout()
        navigate(routerPath.splash, { replace: true });
    }

    const logoutUser = () => {
        setIsLogout(!isLogout)
    }
    const clickProfilePhoto = () => {
        document.getElementById('imageUpload').click()
    }
    const uploadProfileImage = (e) => {
        console.log(e.target.files[0])
        const formData = new FormData()
        formData.append('profile_image', e.target.files[0])
        formData.append('mobile_no', userDetail.customer_phone_number)

        console.log(formData)

        ApiService.post(ApiConfiguration.editprofile, formData, FormConfig).then(res => {
            console.log(res);
            // setalertMessege(`${res.data.data.customer_name} , Your personal profile updated successfully `)
            // setProfileUpdate(true)
            setTimeout(() => {
                getProfileData()
            }, 100);
        }).catch(err => {
            console.log(err);
        })

    }
    return (
        <>
            {
                isLogout &&
                <CommonAlert messege={`Are you sure you want to logout ? `} btn1={`No`} btn2={`Yes`} btn1ClickEvent={cancelLogout} btn2ClickEvent={sendToMobilePage} isSingleBtn={false}></CommonAlert>
            }
            <Container className="user-profile">
                <Row>
                    <Col md={12}>
                        <div className="back-btn-wrapper">
                            <img src={backBtn} alt="" onClick={goBack} />
                        </div>
                        <div className="user-info">
                            <div className="user-img" onClick={clickProfilePhoto}>
                                <img src={profileImg == '' || profileImg == null ? user : profileImg} alt="" />
                                <img src={pencial} alt="" className="pencial-img" />
                                <input type="file" accept="image/*" name="image" hidden id="imageUpload" onChange={uploadProfileImage} />
                            </div>
                            <div className="user-name">
                                <p className="name">
                                    {userData?.customer_name}
                                </p>
                                <p className="number">
                                    +91 {userData?.mobile_no}
                                </p>
                            </div>
                        </div>


                    </Col>

                    <Col md={12}>
                        <div className="list">
                            <div className="list-item" onClick={() => profileTab('home')}>
                                <p>
                                    Home
                                </p>
                                <img src={rightArrow} alt="" />
                            </div>
                            <div className="list-item" onClick={() => profileTab('profile')}>
                                <p>
                                    My Profile
                                </p>
                                <img src={rightArrow} alt="" />
                            </div>
                            <div className="list-item" onClick={() => profileTab('offer')}>
                                <p>
                                    Offer's Activated
                                </p>
                                <img src={rightArrow} alt="" />
                            </div>
                            <div className="list-item" onClick={() => profileTab('about-us')}>
                                <p>
                                    About Us
                                </p>
                                <img src={rightArrow} alt="" />
                            </div>
                            <div className="list-item" onClick={() => profileTab('contact-us')}>
                                <p>
                                    Contact Us
                                </p>
                                <img src={rightArrow} alt="" />
                            </div>
                            <div className="list-item" onClick={logoutUser}>
                                <p>
                                    Logout
                                </p>
                                <img src={rightArrow} alt="" />
                            </div>

                        </div>
                    </Col>
                </Row>
            </Container>
        </>
    )

}

export default UserProfile;