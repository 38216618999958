import React, { useEffect, useState } from "react";
import "./SelectWithSearch.scss";
import { ApiConfiguration } from "../../../services/http/api-configuration";
import ApiService from "../../../services/http/api-service";
import CloseBtn from '../../../../assets/images/Close.png'

const SelectWithSearch = (Props) => {
    const [searchtext, setSearchtext] = useState("");
    const [suggest, setSuggest] = useState([]);
    const [resfound, setResfound] = useState(true);
    const [serchData, setSearchData] = useState('')

    useEffect(() => {
        console.log("city changed")
        clearSearchText()
    }, [Props.cityId])

    const handleChange = (e) => {
        let searchval = e.target.value;
        setSearchData(e.target.value)
        let suggestion = [];
        const ProgLang1 = [
            { id: 1, name: 'Javascript' },
            { id: 2, name: 'Java' },
            { id: 3, name: 'Python' },
            { id: 4, name: 'Nodejs' },
            { id: 5, name: 'Reactjs' },
        ]



        if (searchval.length > 1) {
            const getAutoSeggetData = () => {
                ApiService.get(ApiConfiguration.citylist).then(res => {
                    // console.log(res)
                    suggestion = res.data.data
                        .sort()
                        .filter((e) => e.cityname.toLowerCase().includes(searchval.toLowerCase()));
                    setResfound(suggestion.length !== 0 ? true : false);
                    console.log(suggestion)
                    setSuggest(suggestion);


                }).catch(err => {
                    console.log(err)
                    Props.isError(err)
                })

            }
            getAutoSeggetData()

        }
        setSuggest(suggestion);
        setSearchtext(searchval);

    };

    const suggestedText = (value) => {
        console.log(value);
        Props.valueSelected(value)
        setSearchtext(value.cityname);
        setSuggest([]);
    };

    const clearSearchText = () => {
        setSearchtext('')
        let clearData = {
            id: "",
            cityname: ''
        }
        Props.valueSelected(clearData)

    }

    const getSuggestions = () => {
        if (suggest.length === 0 && searchtext !== "" && !resfound && serchData.length > 1) {
            return <p>Search Content Not Found</p>;
        }
        return (
            <ul>
                {suggest.map((item, index) => {

                    return (
                        <div key={index}>
                            <li onClick={() => suggestedText(item)}>{item.cityname}</li>
                            {index !== suggest.length - 1 && <hr />}
                        </div>
                    );
                })}
            </ul>
        );
    };
    return (
        <div className="select-with-search">
            <input
                type="text"
                placeholder={Props.placeHolder}
                className="search"
                value={searchtext}
                // value={Props.value}
                // className={Props.className}
                onChange={handleChange}
            />
            {
                searchtext.length > 1 &&
                <img src={CloseBtn} alt="" className="clear-btn" onClick={clearSearchText} />
            }

            {getSuggestions()}
        </div>
    );
}

export default SelectWithSearch;