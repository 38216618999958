import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import backBtn from "../../../../assets/images/wrapper.png"
import { useNavigate } from "react-router-dom";
import "./about-us.scss"

const AboutUs = () => {
    const navigate = useNavigate()
    const goBack = () => {
        navigate(-1)
    }
    return (
        <>
            <Container className="about-us-page">
                <Row>
                    <Col md={12}>
                        <div className="back-btn-wrapper">
                            <div>
                                <img src={backBtn} alt="" onClick={goBack} />

                            </div>
                            <p>
                                About Us
                            </p>
                        </div>
                    </Col>


                    <Col md={12} className="px-3">
                        <div className="heading">
                            <h2>
                                About Us
                            </h2>
                        </div>
                        <div className="content">
                            <p>
                                Donec sit amet odio feugiat, tincidunt sapien ac, efficitur tortor. Sed in purus commodo.
                                Donec sit amet odio feugiat, tincidunt sapien ac, efficitur tortor. Sed in purus commodo.Donec sit amet odio feugiat, tincidunt sapien ac, efficitur tortor. Sed in purus commodo.Donec sit amet odio feugiat, tincidunt sapien ac, efficitur tortor. Sed in purus commodo.Donec sit amet odio feugiat, tincidunt sapien ac, efficitur tortor. Sed in purus commodo.Donec sit amet odio feugiat, tincidunt sapien ac, efficitur tortor. Sed in purus commodo.Donec sit amet odio feugiat, tincidunt sapien ac, efficitur tortor. Sed in purus commodo.Donec sit amet odio feugiat, tincidunt sapien ac, efficitur tortor. Sed in purus commodo.
                                Donec sit amet odio feugiat, tincidunt sapien ac, efficitur tortor. Sed in purus commodo.Donec sit amet odio feugiat, tincidunt sapien ac, efficitur tortor. Sed in purus commodo.Donec sit amet odio feugiat, tincidunt sapien ac, efficitur tortor. Sed in purus commodo.
                            </p>
                        </div>
                    </Col>
                </Row>
            </Container>

        </>
    )
}

export default AboutUs;