export const FormConfig = {
    headers: {
        'Content-Type': 'multipart/form-data',
    },
};
export const FormConfigStatic = {
    headers: {
        'Content-Type': 'multipart/form-data',
        'Authorization': ''
    },
};