export const routerPath = {
    splash: '/splash',
    mobile: '/mobile',
    otp: '/otp',
    register: '/register',
    welcomeVideo: '/welcome-video',
    restaurantCode: '/restaurant-code',
    redeemNow: '/redeem-now',
    offers: '/offers',
    qrSuccess: '/qr-success',
    userProfile: '/user-profile',
    myProfile: '/my-profile',
    aboutUs: '/about-us',
    contectUs: '/contect-us',
    redeemOffer: '/redeem-offers'
}