
import { createContext, useContext, useState } from 'react';

export const AuthorizationContext = createContext();


export const AuthorizationProvider = ({ children }) => {
    const [isAuthorized, setIsAuthorized] = useState(localStorage.getItem('auth_token') ? true : false);

    const login = () => {
        // Perform login logic and set isAuthorized to true if successful
        setIsAuthorized(true);
    };

    const logout = () => {
        // Perform logout logic and set isAuthorized to false
        localStorage.clear()
        setIsAuthorized(false);


        // navigate(routerPath.mobile, { replace: true });
    };

    return (
        <AuthorizationContext.Provider value={{ isAuthorized, login, logout }}>
            {children}
        </AuthorizationContext.Provider>
    );
};

export const useAuth = () => {
    return useContext(AuthorizationContext);
};