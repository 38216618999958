
import OnboardRoutes from './onboard-route';
import AuthRoutes from './auth-route';
import { AuthorizationContext } from '../services/auth/AuthorizationContext';
import { useContext, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { routerPath } from './path';

const Navigation = () => {
    const { isAuthorized, login, logout } = useContext(AuthorizationContext);
    const navigate = useNavigate()
    const location = useLocation()

    useEffect(() => {
        console.log('naviagation called')
        if (isAuthorized) {
            if (location.pathname == '' || location.pathname == '/') {
                navigate(routerPath.restaurantCode)
            }
        }
        if (!isAuthorized) {
            console.log('logout!!!')
            logout()
            localStorage.clear()
            sessionStorage.clear()
            navigate(routerPath.splash)

        }

    }, [])
    return (
        <>
            {
                isAuthorized ?
                    <AuthRoutes />
                    :
                    <OnboardRoutes />
            }
        </>
    )
}


export default Navigation;