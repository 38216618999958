import { Routes, Route, redirect, Navigate } from 'react-router-dom';

import Offers from '../pages/offers/offers'
import RedeemNow from '../pages/redeem-now/redeemNow';
import QrSuccess from '../pages/qr-success/qr-success';
import RestaurantCode from '../pages/restaurant-code/restaurant-code';
import UserProfile from '../pages/user-profile/user-profile';
import MyProfile from '../pages/user-profile/my-profile/my-profile';
import AboutUs from '../pages/user-profile/about-us/about-us';
import ContectUs from '../pages/user-profile/contact-us/contact-us';
import Layout from '..//pages/layout/layout';
import { routerPath } from './path';
import RedeemOffers from '../pages/redeem-offers/redeem-offers';
import WelcomeVideo from '../pages/onboarding/wecome-video/welcomeVideo';
import ProtectedRoute from '../services/auth/ProtectedRoute';



const AuthRoutes = () => {
    return (

        <Routes >
            {/* <Route path='/*' element={<ProtectedRoute element={<Layout />} />} /> */}
            <Route path="*" element={<Navigate to={routerPath.restaurantCode} replace />} />
            <Route path={routerPath.restaurantCode} element={<ProtectedRoute element={<RestaurantCode />} />} />
            <Route path={routerPath.welcomeVideo} element={<ProtectedRoute element={<WelcomeVideo />} />} />
            <Route path={routerPath.redeemNow} element={<ProtectedRoute element={<RedeemNow />} />} />
            <Route path={routerPath.offers} element={<ProtectedRoute element={<Offers />} />} />
            <Route path={routerPath.qrSuccess} element={<ProtectedRoute element={<QrSuccess />} />} />
            <Route path={routerPath.userProfile} element={<ProtectedRoute element={<UserProfile />} />} />
            <Route path={routerPath.myProfile} element={<ProtectedRoute element={<MyProfile />} />} />
            <Route path={routerPath.aboutUs} element={<ProtectedRoute element={<AboutUs />} />} />
            <Route path={routerPath.contectUs} element={<ProtectedRoute element={<ContectUs />} />} />
            <Route path={routerPath.redeemOffer} element={<ProtectedRoute element={<RedeemOffers />} />} />
        </Routes>
    )
}


export default AuthRoutes;