import { useContext, useEffect, useRef, useState } from "react";
import { Button } from "react-bootstrap";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import './mobile.scss'
import { ToastContainer, toast } from 'react-toastify';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import ApiService from "../../../services/http/api-service";
import { ApiConfiguration } from "../../../services/http/api-configuration";
import { useNavigate } from 'react-router-dom';
import logo from '../../../../assets/images/Carlsberg-logo.png'
import { AuthorizationContext } from "../../../services/auth/AuthorizationContext";

import Modal from 'react-bootstrap/Modal';
import { routerPath } from "../../../navigation/path";
import CommonAlert from "../../../components/common/common-alert/common-alert"
import loginBanner from "../../../../assets/images/login_banner.png"
import CommonToast from "../../../components/common/toast/common-toast";

const Mobile = () => {
    const { isAuthorized, login, logout } = useContext(AuthorizationContext);

    const navigate = useNavigate()
    const [terms, setTerms] = useState(true)

    const [show, setShow] = useState(false);
    const [modalTitle, setModalTitle] = useState('');
    const [modalBody, setModalBody] = useState('');
    const [isCommonAlert, setCommonAlert] = useState(false);
    const [alertMessege, setalertMessege] = useState('');
    const [privicyAndTerms, setprivicyAndTerms] = useState('')
    const [mobileRes, setMobileRes] = useState({})
    const isLoad = useRef(false)
    const [mobileError, setMobileError] = useState(false)



    useEffect(() => {
        if (!isLoad.current) {
            getPrivicyAndTerms()
            isLoad.current = true
        }
    }, []);

    const getPrivicyAndTerms = () => {
        ApiService.get(ApiConfiguration.welcome).then(res => {
            console.log(res)
            setprivicyAndTerms(res.data.data)
        }).catch(err => {
            console.log(err)
        })
    }

    const validationSchema = Yup.object().shape({

        mobile: Yup.string()
            .required('Mobile number is mandatory')
            .min(10, 'Mobile number should be 10 digit long')
            .max(10, 'Mobile number should be 10 digit long')
            .matches(/^\d{10}$/, 'Mobile number should be 10 digit long'),

        otp: Yup.string()
            .required('Please enter the OTP')
            .min(6, 'Please enter 6 digit long OTP')
            .max(6, 'Please enter 6 digit long OTP')
            .matches(/^\d{6}$/, 'Please enter 6 digit long OTP'),
    });

    const formik = useFormik({
        initialValues: {
            mobile: '',
            otp: ''
        },
        validationSchema,

        // onSubmit: (data) => {
        //     if (!terms) {
        //         setCommonAlert(true)
        //         setalertMessege('Please accept terms and conditions.')
        //         return
        //     }
        //     console.log(data);
        //     let payload = {
        //         pho_no: data.mobile
        //     }
        //     ApiService.post(ApiConfiguration.login, payload)
        //         .then(response => {
        //             console.log(response.data);
        //             navigate(`${routerPath.otp}?mobile_number=${data.mobile}`)
        //             formik.handleReset()
        //         })
        //         .catch(error => {
        //             console.error(error.message);
        //         });
        //     // login()
        // },

        onSubmit: (data) => {
            console.log(data);

            if (!terms) {

                setCommonAlert(true)
                setalertMessege('Please accept the Terms and Conditions')
                removeToast()
                return
            }

            let payload = {
                otp: data.otp,
                contact_number: data.mobile,
                user_identifier: "",
                latitude: "",
                longitude: ""
            }
            ApiService.post(ApiConfiguration.otp, payload).then((res) => {
                // toast(`Login successful!`)
                console.log(res)
                if (res.data.data.verified) {
                    localStorage.setItem('user_detail', JSON.stringify(res.data.data))
                    if (res.data.data.registration_status) {
                        localStorage.setItem('auth_token', JSON.stringify(res.data.data.access_token))
                        login()
                        navigate(routerPath.restaurantCode, { replace: true })
                    } else {
                        console.log(res.data.data.registration_status)
                        navigate(routerPath.register)
                    }
                    formik.handleReset()

                } else {
                    console.log(res)
                    setalertMessege(res.data.data.message)
                    setCommonAlert(true)
                }
            }).catch((e) => {
                console.log(e)
            })

        },
    });

    const termsChanged = (event) => {
        setTerms(event.target.checked)
    }

    const privactClicked = () => {
        setModalTitle(' Privacy Policy ')
        setModalBody(privicyAndTerms.privacy_policy)
        setShow(true)
    }

    const termsClicked = () => {
        setModalTitle(' Terms & Conditions ')
        setModalBody(privicyAndTerms.terms_conditions)
        setShow(true)
    }
    const closeResendPopup = () => setCommonAlert(false)

    const handleClose = () => setShow(false);

    useEffect(() => {
        let regx = /^\d{10}$/

        if (regx.test(formik.values.mobile)) {
            setMobileError(false)

        }
    }, [formik.values.mobile])

    const sendOtp = (msg) => {
        let regx = /^\d{10}$/
        console.log(regx.test(formik.values.mobile))
        if (!regx.test(formik.values.mobile)) {
            setMobileError(true)
            return
        }
        if (formik.values.mobile.length != 10) {
            setCommonAlert(true)
            setalertMessege('Mobile number should be 10 digit long')
            removeToast()
            return
        }
        let payload = {
            pho_no: formik.values.mobile
        }
        console.log(payload)

        ApiService.post(ApiConfiguration.login, payload)
            .then(response => {
                console.log(response.data);
                setCommonAlert(true)
                setalertMessege(msg)

                removeToast()
                // navigate(`${routerPath.otp}?mobile_number=${data.mobile}`)
                // formik.handleReset()
            })
            .catch(error => {
                console.error(error.message);
            });
    }

    const removeToast = () => {

        setTimeout(() => {
            setCommonAlert(false)
        }, 3000);
    }






    return (
        <>
            {
                isCommonAlert &&
                <CommonToast message={alertMessege} />
            }

            {/* {
                isCommonAlert &&
                <CommonAlert messege={alertMessege} btn2={`OK`} btn2ClickEvent={closeResendPopup} isSingleBtn={true}></CommonAlert>
            } */}

            <Container className=" mx-0 mobile-page" fluid>
                <Row>
                    <Col md={12} className="px-0">
                        <div className="logo-wrapper">
                            <img src={loginBanner} alt="" />
                        </div>
                        <div className="instruction-wrapper">

                            <p className="heading-text">
                                Sign In | Sign Up
                            </p>
                            {/* <p className="info-text">
                                Text charges apply.
                            </p> */}
                        </div>
                        <div className="right-container">
                            <div className="form-wrapper">
                                <form onSubmit={formik.handleSubmit}>

                                    <div className="form-group input-margin-bottom" >
                                        <div className="mobile-number-wrapper">
                                            <input
                                                name="mobile"
                                                type="tel"
                                                className={
                                                    'form-control' +
                                                    (formik.errors.mobile && formik.touched.mobile
                                                        ? ' is-invalid'
                                                        : '')
                                                }
                                                onChange={formik.handleChange}
                                                value={formik.values.mobile}
                                                minLength="10"
                                                maxLength="10"
                                                placeholder="Phone number"
                                            />
                                            <button type="button" onClick={e => sendOtp('OTP sent')}>
                                                Send OTP
                                            </button>
                                        </div>
                                        {
                                            mobileError &&
                                            <p className="error-msg">
                                                Mobile number should be 10 digit long
                                            </p>

                                        }
                                        <div className="invalid-feedback">
                                            {formik.errors.mobile && formik.touched.mobile
                                                ? formik.errors.mobile
                                                : null}
                                        </div>
                                    </div>

                                    <div className="form-group">
                                        <input
                                            name="otp"
                                            type="tel"
                                            className={
                                                'form-control' +
                                                (formik.errors.otp && formik.touched.otp
                                                    ? ' is-invalid'
                                                    : '')
                                            }
                                            onChange={formik.handleChange}
                                            value={formik.values.otp}
                                            minLength="6"
                                            maxLength="6"
                                            placeholder="Enter OTP"
                                        />
                                        <div className="invalid-feedback">
                                            {formik.errors.otp && formik.touched.otp
                                                ? formik.errors.otp
                                                : null}
                                        </div>
                                    </div>
                                    <div className="text-center">
                                        <span className="resend-code" onClick={e => sendOtp('OTP resent')}>
                                            Resend the code
                                        </span>
                                    </div>
                                    <div className="terms-wrapper">
                                        <div className="checkbox-wrapper">
                                            <input type="checkbox" value={terms} defaultChecked={true} onChange={termsChanged} />
                                        </div>
                                        <div className="terms-content-wrapper">
                                            <p>
                                                I agree to the <span onClick={termsClicked}> Terms & Conditions </span> & <span onClick={privactClicked}> Privacy Policy</span>  of Carlsberg.
                                            </p>
                                        </div>
                                    </div>
                                    <div className="form-group mt-4 text-end">
                                        <button type="submit" className="btn-carlsberg" >
                                            Submit
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </Col>

                </Row>
            </Container>

            <Modal show={show} onHide={handleClose} centered>
                <Modal.Header closeButton>
                    <Modal.Title>{modalTitle}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div dangerouslySetInnerHTML={{ __html: modalBody }}>
                        {/* {modalBody} */}
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}


export default Mobile;