import { useContext, useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import './otp.scss'
import { ToastContainer, toast } from 'react-toastify';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import ApiService from "../../../services/http/api-service";
import { ApiConfiguration } from "../../../services/http/api-configuration";
import { useNavigate, useSearchParams } from 'react-router-dom';
import logo from '../../../../assets/images/Carlsberg-logo.png'
import CommonHeader from "../../../components/common/common-header/common-header";
import { AuthorizationContext } from "../../../services/auth/AuthorizationContext";
import { routerPath } from "../../../navigation/path";
import CommonAlert from "../../../components/common/common-alert/common-alert";

const Otp = () => {
    const navigate = useNavigate()
    const [userList, setUserList] = useState([]);
    const [alertMessege, setAlertMessege] = useState('')
    const [searchParams] = useSearchParams();
    const mobileNumber = searchParams.get('mobile_number')
    const { isAuthorized, login, logout } = useContext(AuthorizationContext);
    const [isResendOtp, setResendOtp] = useState(false)
    useEffect(() => {

    }, []);

    const validationSchema = Yup.object().shape({

        otp: Yup.string()
            .required('OTP is required')
            .min(6, 'OTP must be at least 4 digit')
            .max(6, 'OTP must not exceed 4 digit')
            .matches(/^\d{6}$/, 'Please enter a valid 6 digit OTP'),
    });

    const resendOtp = () => {
        let payload = {
            pho_no: mobileNumber
        }
        ApiService.post(ApiConfiguration.login, payload)
            .then(response => {
                console.log(response.data);
                setResendOtp(true)
                setAlertMessege(`New OTP has been sent to ${mobileNumber}`)
            })
            .catch(error => {
                console.error(error.message);
            });
    }



    const formik = useFormik({
        initialValues: {
            otp: '',
        },
        validationSchema,
        // validateOnChange: false,
        // validateOnBlur: false,
        onSubmit: (data) => {
            console.log(data);

            let payload = {
                otp: data.otp,
                contact_number: mobileNumber,
                user_identifier: "",
                latitude: "",
                longitude: ""
            }
            ApiService.post(ApiConfiguration.otp, payload).then((res) => {
                // toast(`Login successful!`)
                console.log(res)
                if (res.data.data.verified) {
                    localStorage.setItem('auth_token', JSON.stringify(res.data.data.access_token))
                    localStorage.setItem('user_detail', JSON.stringify(res.data.data))
                    if (res.data.data.registration_status) {
                        login()
                        navigate(routerPath.restaurantCode, { replace: true })
                    } else {
                        console.log(res.data.data.registration_status)
                        navigate(routerPath.register)
                    }

                } else {
                    console.log(res)
                    setAlertMessege(res.data.data.message)
                    setResendOtp(true)
                }
                formik.handleReset()
            }).catch((e) => {
                console.log(e)
            })

        },
    });

    const closeResendPopup = () => {
        setResendOtp(false)
    }


    const clickBackBtn = () => {
        navigate(-1)
    }


    return (
        <>
            {
                isResendOtp &&
                <CommonAlert messege={alertMessege} btn2={`OK`} btn2ClickEvent={closeResendPopup} isSingleBtn={true}></CommonAlert>
            }
            <CommonHeader header="OTP" ></CommonHeader>
            <Container className=" mx-0 otp-page" fluid>
                <Row>
                    <Col md={12} className="px-0">
                        <div className="logo-wrapper">
                            <img src={logo} alt="" />
                        </div>
                        <div className="instruction-wrapper">
                            <p className="heading-text">
                                We sent a 6 digit code to
                            </p>
                            <p className="info-text">
                                + 91 {mobileNumber}
                            </p>
                        </div>
                        <div className="right-container">
                            <div className="form-wrapper">
                                <form onSubmit={formik.handleSubmit}>

                                    <div className="form-group">
                                        <input
                                            name="otp"
                                            type="tel"
                                            className={
                                                'form-control' +
                                                (formik.errors.otp && formik.touched.otp
                                                    ? ' is-invalid'
                                                    : '')
                                            }
                                            onChange={formik.handleChange}
                                            value={formik.values.otp}
                                            minLength="6"
                                            maxLength="6"
                                            placeholder="Enter OTP"
                                        />
                                        <div className="invalid-feedback">
                                            {formik.errors.otp && formik.touched.otp
                                                ? formik.errors.otp
                                                : null}
                                        </div>
                                    </div>

                                    <div className="form-group mt-4 text-end">
                                        <button type="submit" className="btn-carlsberg">
                                            Submit
                                        </button>
                                    </div>

                                    <div className="form-group mt-4 text-center">
                                        Didn't receive OTP ?
                                        <button type="button" onClick={resendOtp} className="resend-btn">
                                            Resend
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </>
    )
}


export default Otp;