import { useContext, useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import './register.scss'
import { ToastContainer, toast } from 'react-toastify';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import ApiService from "../../../services/http/api-service";
import { ApiConfiguration } from "../../../services/http/api-configuration";
import { useNavigate } from 'react-router-dom';
import logo from '../../../../assets/images/Carlsberg-logo.png'
import CommonHeader from "../../../components/common/common-header/common-header";

import { routerPath } from "../../../navigation/path";
import { FormConfig, FormConfigStatic } from "../../../services/http/api-constant";
import { AuthorizationContext } from "../../../services/auth/AuthorizationContext";
import Select from 'react-select';
import SelectWithSearch from "../../../components/common/select-with-search/SelectWithSearch";

const Register = () => {
    // const { setAuth } = useContext(AuthContext)
    const { isAuthorized, login, logout } = useContext(AuthorizationContext);
    const navigate = useNavigate()
    const [userList, setUserList] = useState([]);
    const [citys, setCitys] = useState([])
    const userDetial = JSON.parse(localStorage.getItem('user_detail'))
    const [selectedCity, setSelectedCity] = useState('')
    const submited = false

    const options = [
        { value: 'option1', label: 'Option 1' },
        { value: 'option2', label: 'Option 2' },
        { value: 'option3', label: 'Option 3' },
        // Add more options as needed
    ];
    useEffect(() => {
        console.log(userDetial)
        getCityList()
    }, []);

    const validationSchema = Yup.object().shape({

        mobile: Yup.string()
            .required('mobile number is required')
            .min(10, 'mobile number must be at least 10 digit')
            .max(10, 'mobile number must not exceed 10 digit'),

        name: Yup.string()
            .required('Name is a mandatory field')
            .matches(/^[A-Za-zÀ-ÖØ-öø-ÿ'-\s]+$/, 'Please enter valid name'),

        age: Yup.number()
            .required('Age is a mandatory field')
            .positive('Age must be a positive number')
            .integer('Age must be an number')
            .test('valid-age', 'Invalid age', value => {
                return value > 17;
            }),
        city_id: Yup.string()
            .required('City is a mandatory field'),
    });

    const formik = useFormik({
        initialValues: {
            mobile: userDetial.customer_phone_number,
            name: '',
            age: '',
            city_id: ''
        },
        validationSchema,
        // validateOnChange: false,
        // validateOnBlur: false,
        onSubmit: (data) => {

            const formData = new FormData()
            formData.append('name', data.name)
            formData.append('age', data.age)
            formData.append('city_id', data.city_id)
            console.log(data)
            FormConfigStatic.headers['Authorization'] = `Bearer ${userDetial.access_token}`
            console.log(FormConfigStatic)
            ApiService.post(ApiConfiguration.register, formData, FormConfigStatic).then(res => {
                console.log(res);
                localStorage.setItem('auth_token', JSON.stringify(userDetial.access_token))
                login()
                navigate(`${routerPath.restaurantCode}`, { replace: true })
                formik.handleReset()
            }).catch(err => {
                console.log(err);
            })
        },
    });

    const getCityList = () => {
        ApiService.get(ApiConfiguration.citylist).then(response => {
            setCitys(response.data.data);
        }).catch(error => {
            console.error('Error fetching personal details:', error);

        });
    }

    const citySelected = (e) => {
        console.log(e)
        formik.setFieldValue('city_id', e.id);
        setSelectedCity(e.id)

    }

    return (
        <>
            <CommonHeader header="Register" ></CommonHeader>
            <Container className=" mx-0 register-page" fluid>
                <Row>
                    <Col md={12} className="px-0">
                        <div className="logo-wrapper">
                            <img src={logo} alt="" />
                        </div>
                        <div className="instruction-wrapper">
                            <p className="heading-text">
                                One Time Registration
                            </p>

                        </div>
                        <div className="right-container">
                            <div className="form-wrapper">
                                <form onSubmit={formik.handleSubmit}>

                                    <div className="form-group mb-3">
                                        <label htmlFor="">Mobile Number</label>
                                        <input
                                            name="mobile"
                                            type="tel"
                                            className={
                                                'form-control' +
                                                (formik.errors.mobile && formik.touched.mobile
                                                    ? ' is-invalid'
                                                    : '')
                                            }
                                            onChange={formik.handleChange}
                                            value={formik.values.mobile}
                                            minLength="10"
                                            maxLength="10"
                                            placeholder="Phone number"
                                            disabled
                                        />
                                        <div className="invalid-feedback">
                                            {formik.errors.mobile && formik.touched.mobile
                                                ? formik.errors.mobile
                                                : null}
                                        </div>
                                    </div>
                                    <div className="form-group mb-3">
                                        <label htmlFor="">Name</label>

                                        <input
                                            name="name"
                                            type="text"
                                            className={
                                                'form-control' +
                                                (formik.errors.name && formik.touched.name
                                                    ? ' is-invalid'
                                                    : '')
                                            }
                                            onChange={formik.handleChange}
                                            value={formik.values.name}
                                            placeholder="Name"
                                        />
                                        <div className="invalid-feedback">
                                            {formik.errors.name && formik.touched.name
                                                ? formik.errors.name
                                                : null}
                                        </div>
                                    </div>

                                    <div className="form-group mb-3">
                                        <label htmlFor="">Age</label>

                                        <input
                                            name="age"
                                            type="number"
                                            className={
                                                'form-control' +
                                                (formik.errors.age && formik.touched.age
                                                    ? ' is-invalid'
                                                    : '')
                                            }
                                            onChange={formik.handleChange}
                                            value={formik.values.age}
                                            minLength="1"
                                            maxLength="3"
                                            placeholder="Age"
                                        />
                                        <div className="invalid-feedback">
                                            {formik.errors.age && formik.touched.age
                                                ? formik.errors.age
                                                : null}
                                        </div>
                                    </div>
                                    {/* <div className="form-group mb-3">
                                        <label htmlFor="">City</label>
                                        <select onChange={formik.handleChange}
                                            value={formik.values.city_id}

                                            className={
                                                'form-control' +
                                                (formik.errors.city_id && formik.touched.city_id
                                                    ? ' is-invalid'
                                                    : '')
                                            } name="city_id">
                                            <option value="">Select City</option>
                                            {citys.map((city) => <option value={city.id}>{city.cityname}</option>)}
                                        </select>
                                        <div className="invalid-feedback">
                                            {formik.errors.city_id && formik.touched.city_id
                                                ? formik.errors.city_id
                                                : null}
                                        </div>
                                    </div> */}

                                    <div className="form-group">
                                        <label htmlFor="">City </label>
                                        <SelectWithSearch valueSelected={citySelected} placeHolder={'Search City'}
                                            className={

                                                (formik.errors.city_id && formik.touched.city_id
                                                    ? ' is-invalid'
                                                    : '')
                                            } name="city_id" />
                                        <div className="invalid-feedback">
                                            {
                                                formik.errors.city_id && formik.touched.city_id
                                                    ? formik.errors.city_id
                                                    : null
                                            }
                                        </div>
                                    </div>



                                    <div className="form-group mt-4 text-end">

                                        <button type="submit" className="btn-carlsberg">
                                            Submit
                                        </button>
                                    </div>



                                </form>
                            </div>
                        </div>

                    </Col>

                </Row>
            </Container>
        </>
    )
}

export default Register