import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import './globle.css';
import './variable.css'
import App from './app/App';
import reportWebVitals from './reportWebVitals';
// import { BrowserRouter, Route, Routes } from 'react-router-dom';


const root = ReactDOM.createRoot(document.getElementById('root'));

// const injectGA = () => {
//   if (typeof window == 'undefined') {
//     return;
//   }
//   window.dataLayer = window.dataLayer || [];
//   function gtag() {
//     window.dataLayer.push(arguments);
//   }
//   gtag('js', new Date());

//   gtag('config', 'G-X8XEW2L8C3');
// };
// <!-- Google Tag Manager -->
(function (w, d, s, l, i) {
  w[l] = w[l] || []; w[l].push({
    'gtm.start':
      new Date().getTime(), event: 'gtm.js'
  }); var f = d.getElementsByTagName(s)[0],
    j = d.createElement(s), dl = l != 'dataLayer' ? '&l=' + l : ''; j.async = true; j.src =
      'https://www.googletagmanager.com/gtm.js?id=' + i + dl; f.parentNode.insertBefore(j, f);
})(window, document, 'script', 'dataLayer', 'GTM-N8PN9PMX');
// <!-- End Google Tag Manager -->
root.render(

  <React.StrictMode>
    {/* <script
      async
      src="https://www.googletagmanager.com/gtag/js?id=G-X8XEW2L8C3"
    />
    <script>{injectGA()}</script> */}

    <noscript><iframe src="https://www.googletagmanager.com/ns.html?id=GTM-N8PN9PMX"
      height="0" width="0" style="display:none;visibility:hidden"></iframe></noscript>
    {/* <BrowserRouter>
      <Routes>
        <Route path='/*' element={<App />}></Route>
      </Routes>
    </BrowserRouter> */}
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
