import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import backBtn from "../../../../assets/images/wrapper.png"
import { useNavigate } from "react-router-dom";
import "./contact-us.scss"
import contectImg from "../../../../assets/images/contect-us.png";
import call from "../../../../assets/images/call.png"

const ContectUs = () => {
    const navigate = useNavigate()
    const goBack = () => {
        navigate(-1)
    }
    return (
        <>
            <Container className="contect-us-page">
                <Row>
                    <Col md={12} className="mt-3">
                        <div className="back-btn-wrapper">
                            <div>
                                <img src={backBtn} alt="" onClick={goBack} />

                            </div>
                            <p>
                                Get in Touch
                            </p>
                        </div>
                    </Col>
                    <Col md={12}>
                        <div className="heading">
                            <h1>
                                Helpline Call
                            </h1>
                            <p>
                                For any questions and queries on the
                                Carlsberg. Please give a miss call on the following number
                            </p>
                        </div>
                        <div className="contect-img-wrapper">
                            <img src={contectImg} alt="" />
                        </div>
                        <div className="miss-call-detail">
                            <p>
                                Give A Missed Call On
                            </p>
                            <div>
                                <button>
                                    <img src={call} alt="" /> +91-89551231211
                                </button>
                            </div>
                        </div>
                        <div className="email-detail">
                            <p className="title">
                                You can also write to us on
                            </p>
                            <p className="email">
                                Carlsberg@gmail.com
                            </p>
                        </div>
                    </Col>

                </Row>
            </Container>
        </>
    )
}

export default ContectUs