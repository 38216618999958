import './common-alert.scss'

const CommonAlert = (Props) => {
    return (
        <>
            <div className="common-alert">
                <div className="alert-wrapper">
                    <div className='massege-wrapper'>
                        <p>
                            {Props.messege}
                        </p>
                    </div>
                    <div className='button-wrapper'>
                        {
                            !Props.isSingleBtn &&
                            <div className='right-border'>
                                <button onClick={Props.btn1ClickEvent}>
                                    {Props.btn1}
                                </button>
                            </div>
                        }
                        <div>
                            <button onClick={Props.btn2ClickEvent}>
                                {Props.btn2}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default CommonAlert;