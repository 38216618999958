import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import splashImg from '../../../../assets/images/splash.pag.png'
import { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { routerPath } from "../../../navigation/path";
import './splash.scss';
import { AuthorizationContext } from "../../../services/auth/AuthorizationContext";
import { useContext } from "react";

const Splash = () => {
    const { isAuthorized, login, logout } = useContext(AuthorizationContext);

    const isPageLoadOnes = useRef(false)
    const navigate = useNavigate()
    useEffect(() => {
        console.log(isAuthorized)
        localStorage.clear()
        sessionStorage.clear()
        if (!isPageLoadOnes.current) {
            setTimeout(() => {
                navigate(`${routerPath.mobile}`)
            }, 3000)
            isPageLoadOnes.current = true
        }
    }, [])

    useEffect(() => {
        const handleBackButton = (e) => {
            console.log(e)
            e.preventDefault();
            console.log('handleBackButton')
            navigate(routerPath.splash, { replace: true })
            // if (!isAuthorized) {
            //     localStorage.clear()
            // }
        };

        // Add event listeners
        window.addEventListener('popstate', handleBackButton);


        // Clean up the event listeners on component unmount
        return () => {
            window.removeEventListener('popstate', handleBackButton);


        };
    }, [navigate]);
    return (
        <>
            <div className="splash-wrapper">
                <img src={splashImg} alt="" />
            </div>
        </>
    )

}
export default Splash;