import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import './redeem-offers.scss'
import { useEffect, useRef, useState } from "react";
import offerThumbnil from '../../../assets/images/offer-thabnil.png'
import { useNavigate } from "react-router-dom";
import Navbar from "../../components/common/navbar/navbar";
import { routerPath } from "../../navigation/path";
import ApiService from "../../services/http/api-service";
import { ApiConfiguration } from "../../services/http/api-configuration";
import CommonHeader from "../../components/common/common-header/common-header";
import backBtn from "../../../assets/images/wrapper.png"

const RedeemOffers = () => {

    const [redeeedOfferList, setRedeemedOfferList] = useState([])
    const navigate = useNavigate()


    const isApiLoadOnes = useRef(false)

    useEffect(() => {
        console.log(isApiLoadOnes)
        if (!isApiLoadOnes.current) {
            getRedeemedOffers()
            isApiLoadOnes.current = true
        }
    }, [])





    const getRedeemedOffers = () => {
        ApiService.get(ApiConfiguration.redeemedList).then(res => {
            console.log(res + "redeemed offers")
            setRedeemedOfferList(res.data.data)
        }).catch(err => {
            console.log(err)
        })
    }
    const goBack = () => {
        navigate(-1)

    }
    console.log(redeeedOfferList)
    return (
        <>
            {/* <Navbar></Navbar> */}
            {/* <CommonHeader header={'My redemption'}></CommonHeader> */}

            <Container className="redeem-offers-page">
                <Row>
                    <Col md={12}>
                        <div className="back-btn-wrapper">
                            <div>
                                <img src={backBtn} alt="" onClick={goBack} />

                            </div>
                            <p>
                                Offer's Activated
                            </p>
                        </div>
                    </Col>
                    <Col md={12}>
                        {
                            redeeedOfferList.map((item) => (
                                <div className="offer-wrapper">
                                    <div className="offer-img-wrapper">
                                        <img src={item.thumbnail} alt="" />
                                    </div>
                                    <div className="offer-info-wrapper">
                                        <p className="offer-name">
                                            {item.offer_name}
                                        </p>
                                        <p className="offer-valid-data">
                                            Offer Claimed Date {item.staff_scaned_at}

                                        </p>
                                        <span className="offer-category">
                                            {item.restaurant_name}
                                        </span>

                                    </div>
                                </div>
                            ))
                        }
                        {
                            redeeedOfferList.length == 0 &&
                            <div className="no-remption">
                                <p>
                                    No Offer Activated
                                </p>
                            </div>
                        }
                    </Col>
                </Row>
            </Container>
        </>
    )
}

export default RedeemOffers;