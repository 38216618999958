import Carousel from 'react-bootstrap/Carousel';
import offerThumbnil from '../../../../assets/images/banner.png'
import './common-carousel.scss'

const CommonCarousel = (Props) => {
    return (
        <div className='common-carousel'>
            <Carousel data-bs-theme="dark" indicators="false">
                {
                    Props.bannerImg.map((item) => (
                        <Carousel.Item>
                            <img
                                className="d-block w-100"
                                src={item.image}
                                alt="First slide"
                            />

                        </Carousel.Item>

                    ))
                }

            </Carousel>
        </div>
    )
}

export default CommonCarousel