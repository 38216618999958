
// development
// export const baseUrl = 'https://appapi.carlsberguat.bigcityvoucher.co.in/v1'
// production
export const baseUrl = "https://appapi.carlsberguat.bigcitydays.in/v1"
//development uat

export const ApiConfiguration = {
    login: baseUrl + "/customer/login",
    otp: baseUrl + "/customer/verifyotp",
    resendOtp: baseUrl + "/customer/resendotp",
    register: baseUrl + "/customer/register",
    offerList: baseUrl + "/customer/listofferbyrestcode",
    redeemedList: baseUrl + "/customer/readredeemedbycustomer",
    qrSuccess: baseUrl + "/customer/generatecustomerofferqr",
    myprofile: baseUrl + "/customer/myprofile",
    editprofile: baseUrl + "/customer/editprofile",
    citylist: baseUrl + "/user/getcitylist",
    welcome: baseUrl + "/customer/welcome",
    autoSuggest: baseUrl + "/customer/searchrestaurant",
    welcomeContent: baseUrl + "/customer/welcomecontent",
    dashboardBanner: baseUrl + "/customer/customerdashboard",
    cityForSearch: baseUrl + "/customer/getrestaurantcity"


}