import './navbar.scss'
import { useNavigate } from 'react-router-dom'
import CloseBtn from '../../../../assets/images/Close.png'
import logo from '../../../../assets/images/Carlsberg-logo.png'
import hamburger from '../../../../assets/images/Hamburger.png'
import navuser from '../../../../assets/images/nav-user.png'
import notification from '../../../../assets/images/Notifications icon.png'
import { routerPath } from '../../../navigation/path'


const Navbar = (Props) => {
    const navigate = useNavigate()
    const goToUserProfile = () => {
        navigate('/user-profile')
    }

    const gotoChangeRestaurant = () => {
        navigate(routerPath.restaurantCode)

    }
    return (
        <>
            <div className='navbar'>
                <div className="logo-wrapper">
                    <img src={navuser} alt="" className='user-img' />
                    <img src={logo} alt="" className='logo-img' />
                </div>
                <div className="hamburger-menus">
                    <div className="change-resto" onClick={gotoChangeRestaurant}>
                        Change Restaurant
                    </div>
                    {/* <div className='notifications'>
                        <img src={notification} alt="" />
                    </div> */}
                    <img className='expand-img' src={hamburger} alt="" onClick={goToUserProfile} />

                </div>

            </div>
        </>
    )
}

export default Navbar;