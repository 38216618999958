import './common-toast.scss'

const CommonToast = (Props) => {
    return (
        <>
            <div className="common-toast">
                <div className="alert-wrapper">
                    <div className='massege-wrapper'>
                        <p>
                            {Props.message}
                        </p>
                    </div>
                </div>
            </div>
        </>
    )
}

export default CommonToast;