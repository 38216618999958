import './common-header.scss'
import { useNavigate, useLocation } from 'react-router-dom';
import CloseBtn from '../../../../assets/images/Close.png'
import { AuthorizationContext } from '../../../services/auth/AuthorizationContext';
import { useContext } from 'react';



const CommonHeader = (Props) => {
    const { isAuthorized, login, logout } = useContext(AuthorizationContext);

    const navigate = useNavigate()
    const location = useLocation();
    const ClickBackBtn = () => {
        isAuthorized && location.pathname == '/restaurant-code' ?
            navigate('/restaurant-code')
            :
            navigate(-1)
    }


    // useEffect(() => {
    //     console.log(history)
    // }, [])
    return (
        <>
            <div className='common-header'>
                <h3>
                    {Props.header}
                </h3>
                <img src={CloseBtn} alt="" onClick={ClickBackBtn} />

            </div>
        </>
    )
}

export default CommonHeader;